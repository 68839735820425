// // src/config.js

// const config = {
//     apiUrl: "http://127.0.0.1:8000",
//     imgUrl:"http://127.0.0.1:8000/storage/"
//   };
  
//   export default config;
  



 // src/config.js

const config = {
  apiUrl: "https://api1.amamaza.rw",
  imgUrl:"https://api1.amamaza.rw/storage/"
};

export default config;
